import { hillo } from 'hillo'
import dayjs from 'dayjs'
import GlobalConfig from '@/GlobalSettings'

import IKUtils from 'innerken-js-utils'
import { printSummaryBon } from '@/common/Utlis/api'
import _ from 'lodash'
import { comparator } from '@/model/DashboardService'

const todayDateHourSecond = dayjs().subtract(3, 'h').subtract(59, 'm').format('YYYY-MM-DD')
const todayDate = dayjs().format('YYYY-MM-DD')

export async function previewZBonByTimeSpan (startTime, endTime) {
  return (await hillo.get('ZBon.php?op=previewByTimeSpan', {
    startTime,
    endTime,
  })).content
}

export async function getLastZBonInfo () {
  // const list = await getZbonRecordList()
  const list = await getZbonList()
  return list[0]
}

// 后端接口经常切换
export async function getStartAndEndTimeForToday () {
  const startTime = (await getLastZBonInfo()).printTimeStamp
  console.log('startTime', startTime, dayjs().format('YYYY-MM-DD HH:mm:ss'))

  return [(await getLastZBonInfo()).printTimeStamp, dayjs().format('YYYY-MM-DD HH:mm:ss')]
}

export async function getServantList () {
  return (await hillo.get('Servant.php')).content
}

export async function getBillListForServant (pw = null, date) {
  return (await hillo.get('BackendData.php?op=mobileV3StatWithLang', {
    pw,
    date,
    lang: GlobalConfig.lang,
  })).content
}

export async function getDiscountAndStornOrders () {
  const timeArr = await getStartAndEndTimeForToday()

  return (await hillo.get('Orders.php?op=withSortAndFilter', {
    lang: GlobalConfig.lang,
    timespan: timeArr[0].split(' ')[0] + ' - ' + timeArr[1].split(' ')[0],
  })).content
}

export async function printServantSummaryForToday (pw) {
  // 此处是从上次打印截止日期到现在
  const date = (await getStartAndEndTimeForToday()).map(s => s.split(' ')[0])

  console.log('date', date)

  // return printServantSummary(pw, ...date)
  return printServantSummary(pw, ...date)
}

export async function printAllServantSummaryForToday () {
  const res = await servantList()
  res.forEach(s => printServantSummaryForToday(s.servant.password))
}

export async function servantList () {
  return (await getLastZbonSlotIndexInfos()).servantList.map(bill => {
    const res = _.sumBy(bill.orders.filter(i => i.payMethodId === '0'), function (o) {
      return parseFloat(o.totalPrice)
    })
    return {
      ...bill,
      ...bill.servant,
      notPay: res,
    }
  }).sort((a, b) => comparator(
    b.orders?.length ?? 0,
    a.orders?.length ?? 0,
  ))
}

export async function printServantSummary (pw, start, end) {
  return (await hillo.get('Servant.php?op=printSummaryBonByPassword', {
    pw,
    start,
    end,
  })).content
}

export async function getLastZbonSlotIndexInfos () {
  const taxInfo = await previewZBonByTimeSpan(
    ...await getStartAndEndTimeForToday(),
  )
  const servantList = await Promise.all((await getServantList())
    .map(async s => await getBillListForServant(s.password, todayDateHourSecond)))
  return {
    ...taxInfo,
    servantList,
  }
}

export async function getZbonRecordList (fromDateTime, toDateTime) {
  return (await hillo.get('ZBon.php?op=searchZbonCombinedRecord')).content
}

export async function getZbonList (filter) {
  return (await hillo.get('ZBon.php', { lang: GlobalConfig.getLang(), ...filter }))
    .content
}

export async function reprintZbon (zbonNumber) {
  return (await hillo.post('ZBon.php?op=printCombined', { zbonNumber }))
}

export async function generateAndPrintZbon () {
  return (await hillo.get('ZBon.php?op=generateAndPrintCombined')).content
  // return (await hillo.get('ZBon.php?op=printZbon')).content
}

export async function printZbonToday () {
  IKUtils.showConfirm(
    this.$t('are_you_sure'),
    this.$t('operate_to_print_a_ZBon'), async () => {
      await generateAndPrintZbon()
    })
}

export async function printXbon () {
  IKUtils.showConfirm(
    this.$t('are_you_sure'),
    this.$t('operate_to_print_a_XBon'),
    async () => {
      await printSummaryBon({ timespan: todayDate + ' - ' + todayDate })
    })
}

export async function getAllDashBoardInfoToday () {
  return await hillo.get('DashboardData.php?', {
    startDate: todayDateHourSecond,
    endDate: todayDate,
    lang: GlobalConfig.lang,
  })
}

export function i18nObjectTrans (list) {
  const res = list.map(item => {
    item.text = this.$t(item.text)
    return item
  })
  return res
}
