import hillo from 'hillo'
import { GlobalConfigLang } from '@/GlobalSettings'
// import i18n from '@/i18n'

export async function loadDashboardData (dateRange) {
  const [startDate, endDate] = dateRange
      return await hillo.get('DashboardData.php', {
      startDate: startDate, endDate: endDate, lang: GlobalConfigLang.lang,
    })
}
export async function loadServantList () {
  return (await hillo.get('Servant.php')).content.map(s => ({
    ...s,
    filterSet: 'servant',
  }))
}

export async function loadBillListForServant (pw = null, dateRange) {
  const [date, endDate] = dateRange
  return (await hillo.get('BackendData.php?op=mobileV3StatWithLang', {
    pw, date, endDate,
  })).content
}
export async function getAllServantInfo (dateRange) {
  return await Promise.all((await loadServantList())
    .map(async s => await loadBillListForServant(s.password, dateRange)))
}

export function comparator (a, b) {
  if (parseFloat(a) < parseFloat(b)) {
    return -1
  }
  if (parseFloat(a) > parseFloat(b)) {
    return 1
  }
  return 0
}
